.wrapper {
    position: relative;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

.content {
    max-width: 90%;
}

.text404 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-top: 1em;
    color: #c9c9c9;
}

@-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}
